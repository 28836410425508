import { Link } from "gatsby"
import React from "react"
import { Container, Button } from "reactstrap"

export default function ThankYou(props) {
  return (
    <Container fluid>
      <div
        className="d-flex flex-column justify-content-center my-5 py-5 text-center"
        style={{ minHeight: "30rem" }}
      >
        <div className="mb-5">
          <h1 className="mb-4 text-primary">Vielen Dank!</h1>
          <p className="mb-2">
            Deine Bestellung wird nun bearbeitet und schnellstmöglich versandt.
          </p>
          <p>
            Bei Fragen schreib mir gerne eine E-Mail an{" "}
            <a href="mailto:info@artgreetings.de">info@artgreetings.de</a>.
          </p>
          <Button
            className="mt-5 px-4"
            color="primary"
            size="lg"
            outline
            tag={Link}
            to={"/"}
          >
            Weiter stöbern
          </Button>
        </div>
      </div>
    </Container>
  )
}
