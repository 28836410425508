import React from "react"
import { PayPalButtons } from "@paypal/react-paypal-js"
import { useShoppingCart } from "use-shopping-cart"

const Paypalbutton = (props) => {
  const { setStep } = props
  const { cartDetails, totalPrice, clearCart } = useShoppingCart()
  const cart = Object.entries(cartDetails).map(([k, v]) => v)
  const discount = (100 - props.discount) / 100

  const createOrder = (data, actions) => {
    const amount = {
      value: ((totalPrice / 100) * discount + 1.6).toFixed(2).toString(),
      currency_code: "EUR",
      breakdown: {
        item_total: {
          value: ((totalPrice / 100) * discount).toFixed(2).toString(),
          currency_code: "EUR",
        },
        shipping: { value: "1.60", currency_code: "EUR" },
      },
    }
    const items = cart.map((i) => {
      return {
        name: `${i.name}${i.variation}${i.personalization}`,
        unit_amount: {
          value: ((i.price / 100) * discount).toFixed(2).toString(),
          currency_code: "EUR",
        },
        quantity: i.quantity.toString(),
        category: "PHYSICAL_GOODS",
      }
    })
    const payer = {
      name: props.shipping.name,
      address: props.shipping.address,
    }
    const shipping = {
      name: {
        full_name:
          props.shipping.name.given_name + " " + props.shipping.name.surname,
      },
      address: props.shipping.address,
      // options: [
      //   {
      //     id: "STANDARD",
      //     label: "Standardversand",
      //     type: "SHIPPING",
      //     selected: true,
      //     amount: {
      //       value: "1.60",
      //       currency_code: "EUR",
      //     },
      //   },
      // ],
    }

    return actions.order.create({
      purchase_units: [{ amount, items, shipping }],
      payer,
      application_context: {
        brand_name: "ArtGreetings",
        shipping_preference: "SET_PROVIDED_ADDRESS",
      },
    })
  }

  const onApprove = (data, actions) => {
    // Capture the funds from the transaction

    return actions.order.capture().then(function (details) {
      setStep("success")
      clearCart()

      try {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: "purchase",
          transaction_id: details.id,
          currency: "EUR",
          value: ((totalPrice / 100) * discount + 1.6).toFixed(2),
          coupon: props.coupon,
          items: cart.map((i) => {
            return {
              item_id: i.id,
              item_name: `${i.name}${i.variation}${i.personalization}`,
              quantity: i.quantity,
              price: ((i.price / 100) * discount).toFixed(2),
            }
          }),
        })

        // cart.map((i) => {
        //   dataLayer.push({
        //     event: "Kauf_GT",
        //     color: i.color,
        //     year: i.year,
        //     transaction_id: details.id,
        //     price: i.price,
        //   })
        // })
        // dataLayer.push({
        //   event: "orderCompleted",
        //   transactionId: details.id, // Transaction ID. Required  for purchases and refunds.
        //   transactionTotal: cart
        //     .map((i) => i.price)
        //     .reduce((a, b) => {
        //       return Math.round((Number(a) + Number(b)) * 100) / 100
        //     }, 0.0), // Total transaction value (incl. tax and shipping)
        //   transactionShipping: 0,
        //   transactionProducts: cart.map((i) => {
        //     return {
        //       name: `${i.name}${i.year ? " " + i.year : ""}`,
        //       sku: `${i.name}${i.year ? " " + i.year : ""}`,
        //       price: i.price,
        //       quantity: 1,
        //     }
        //   }),
        // })
      } catch (e) {
        console.log(e)
      }
    })
  }

  return (
    <div className={props.className}>
      <h6 className="mb-3 pb-1 text-muted border-bottom">
        Wähle eine Bezahlmethode:
      </h6>
      {props.shipping && (
        <PayPalButtons
          style={{ color: "white", tagline: false }}
          forceReRender={[discount]}
          createOrder={createOrder}
          onApprove={onApprove}
        />
      )}
    </div>
  )
}
export default Paypalbutton
