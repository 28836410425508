import React, { useState } from "react"
import { Formik, Form, Field } from "formik"
import * as Yup from "yup"
import styled from "styled-components"

import { AiOutlineCheck } from "react-icons/ai"
import { Input, Button, FormFeedback } from "reactstrap"

export default function Discount({ discount, setDiscount, setCoupon }) {
  const [isOpen, setIsOpen] = useState(false)

  if (!isOpen && discount === 0)
    return (
      <Button
        className="px-0 mb-3"
        size="sm"
        color="link"
        onClick={() => setIsOpen(true)}
      >
        Rabattcode anwenden
      </Button>
    )

  const validationSchema = Yup.object().shape({
    discountCode: Yup.string().matches(/(opening22)/, "Ungültiger Code"),
  })

  return (
    <Formik
      initialValues={{ discountCode: "" }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        if (values.discountCode === "opening22") {
          setDiscount(10)
          setCoupon("opening22")
        }
      }}
    >
      {({ errors, touched }) => (
        <StyledForm className="d-flex align-items-start">
          <div className="mb-3">
            <Field
              name="discountCode"
              as={Input}
              size="sm"
              disabled={discount}
              placeholder="Rabattcode"
              invalid={errors.discountCode && touched.discountCode}
            />
            <FormFeedback>{errors.discountCode}</FormFeedback>
          </div>
          <Button
            color={discount !== 0 ? "primary" : "dark"}
            className="ml-2"
            size="sm"
            disabled={discount !== 0}
            discount={discount}
            type="submit"
          >
            {discount !== 0 ? (
              <>
                <AiOutlineCheck size={14} className="mr-2" />
                Eingelöst
              </>
            ) : (
              "Anwenden"
            )}
          </Button>
        </StyledForm>
      )}
    </Formik>
  )
}

const StyledForm = styled(Form)`
  input {
    width: 12rem;
  }
  .btn {
    :focus {
      background-color: ${({ theme, discount }) =>
        discount ? theme.primary : theme.dark};
      border-color: ${({ theme }) => theme.primary};
    }
  }
`
