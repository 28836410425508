import React, { useState } from "react"
import { Link } from "gatsby"
import { useShoppingCart } from "use-shopping-cart"
import { PayPalScriptProvider } from "@paypal/react-paypal-js"
import styled from "styled-components"

import { Row, Col, Container, ListGroup, ListGroupItem } from "reactstrap"
import { Card, Collapse, Button } from "reactstrap"
import { BsPencil, BsChevronDown, BsChevronUp } from "react-icons/bs"

import Layout from "../templates/layout/layout"
import SEO from "../templates/layout/seo"
import Shipping from "../components/shipping"
import Discount from "../components/discount"
import Paypalbutton from "../components/paypalbutton"
import Success from "../components/success"

export default function Checkout(props) {
  const [step, setStep] = useState("shipping")
  const [shipping, setShipping] = useState(null)
  const clientId = process.env.GATSBY_PAYPAL_CLIENT_ID

  return (
    <PayPalScriptProvider
      options={{
        "client-id": clientId,
        currency: "EUR",
        "disable-funding": ["sofort", "giropay"],
      }}
    >
      <Layout>
        <SEO title="Kasse - ArtGreeetings" />
        {["shipping", "payment"].includes(step) && (
          <ShippingPayment
            step={step}
            shipping={shipping}
            setStep={setStep}
            setShipping={setShipping}
          />
        )}
        {step === "success" && <Success />}
      </Layout>
    </PayPalScriptProvider>
  )
}

const Nav = (props) => {
  const { shipping, step, setStep } = props

  return (
    <StyledNav className="small">
      <Button className="pl-0" color="link" size="sm" tag={Link} to={"/cart"}>
        Warenkorb
      </Button>
      <svg
        width="10px"
        hight="10px"
        viewBox="0 0 10 10"
        className="_1fragemi _1fragem1d _1fragem4m _1fragem4l"
        focusable="false"
        aria-hidden="true"
      >
        <path d="M2 1l1-1 4 4 1 1-1 1-4 4-1-1 4-4"></path>
      </svg>
      <Button
        color="link"
        size="sm"
        active={step === "shipping"}
        onClick={() => setStep("shipping")}
      >
        Adresse
      </Button>
      <svg
        width="10px"
        hight="10px"
        viewBox="0 0 10 10"
        className="_1fragemi _1fragem1d _1fragem4m _1fragem4l"
        focusable="false"
        aria-hidden="true"
      >
        <path d="M2 1l1-1 4 4 1 1-1 1-4 4-1-1 4-4"></path>
      </svg>
      <Button
        color="link"
        size="sm"
        disabled={!shipping || step === "payment"}
        active={step === "payment"}
        onClick={() => setStep("payment")}
      >
        Zahlung
      </Button>
    </StyledNav>
  )
}

const ShippingPayment = (props) => {
  const { shipping, step, setStep, setShipping } = props
  const [discount, setDiscount] = useState(0)
  const [coupon, setCoupon] = useState("")

  return (
    <Container fluid>
      <div className="mt-4 mt-md-5 mb-3">
        <h1>Kasse</h1>
        <Nav step={step} shipping={shipping} setStep={setStep} />
      </div>
      <OrderSummaryMin step={step} shipping={shipping} discount={discount} />
      <Row className="mb-5 pb-5">
        <Col lg="7" className="mt-4">
          {step === "shipping" && (
            <Shipping
              shipping={shipping}
              setStep={setStep}
              setShipping={setShipping}
            />
          )}
          {step === "payment" && (
            <>
              <Discount
                discount={discount}
                setDiscount={setDiscount}
                setCoupon={setCoupon}
              />
              <Paypalbutton
                shipping={shipping}
                discount={discount}
                coupon={coupon}
                setStep={setStep}
              />
            </>
          )}
        </Col>
        <Col xs="12" lg="5" xl={{ size: 4, offset: 1 }}>
          <OrderSummary
            step={step}
            shipping={shipping}
            discount={discount}
            setStep={setStep}
          />
        </Col>
      </Row>
    </Container>
  )
}

const OrderSummary = ({ shipping, discount, setStep }) => {
  const { cartCount, totalPrice, formattedTotalPrice } = useShoppingCart()
  const sum = parseFloat(+((totalPrice / 100) * ((100 - discount) / 100) + 1.6))
    .toFixed(2)
    .replace(".", ",")
  const discountSum = parseFloat(+((totalPrice / 100) * (discount / 100)))
    .toFixed(2)
    .replace(".", ",")

  return (
    <div className="pl-lg-5 pl-xl-0">
      <Card body className="d-none d-lg-block mb-3 bg-light">
        <div className="d-flex justify-content-between align-items-start">
          <h5 style={{ fontWeight: 400 }}>Bestellübersicht</h5>
          <Button
            tag={Link}
            className="pt-0 pr-0"
            color="link"
            size="sm"
            to={"/cart"}
          >
            <BsPencil size={18} />
          </Button>
        </div>
        <ListGroup flush>
          <ListGroupItem className="px-0 bg-light pb-0 border-0">
            <div className="d-flex">
              <span>{cartCount} Artikel</span>
              <span className="ml-auto">{formattedTotalPrice}</span>
            </div>
            <OrderDetails />
          </ListGroupItem>
          {discount !== 0 && (
            <ListGroupItem className="d-flex p-0 bg-light border-0">
              <span>Rabatt</span>
              <span className="ml-auto font-size-sm">- {discountSum} €</span>
            </ListGroupItem>
          )}
          <ListGroupItem className="d-flex px-0 pt-0 bg-light">
            <span>Versandkosten</span>
            <span className="ml-auto font-size-sm">1,60 €</span>
          </ListGroupItem>
          <ListGroupItem className="d-flex bg-light lead px-0 pb-4 ">
            <span>Summe</span>
            <span className="ml-auto">{sum} €</span>
          </ListGroupItem>
          <ShippingInfo shipping={shipping} setStep={setStep} />
          <ListGroupItem className="d-flex justify-content-center text-center bg-light py-4">
            <small>Deine Bestellung wird in 1-3 Tagen versandt.</small>
          </ListGroupItem>
        </ListGroup>
      </Card>
    </div>
  )
}

const OrderSummaryMin = ({ discount, shipping, setStep }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { cartCount, totalPrice, formattedTotalPrice } = useShoppingCart()
  const sum = parseFloat(+((totalPrice / 100) * ((100 - discount) / 100) + 1.6))
    .toFixed(2)
    .replace(".", ",")
  const discountSum = parseFloat(+((totalPrice / 100) * (discount / 100)))
    .toFixed(2)
    .replace(".", ",")

  return (
    <StyledMinCard className="d-lg-none mb-2 bg-light">
      <div className="card-body">
        <div
          className="d-flex justify-content-between align-items-start"
          onClick={() => setIsOpen(!isOpen)}
        >
          <h6 className="lead text-link mb-0" style={{ fontWeight: 400 }}>
            Bestellübersicht{" "}
            {!isOpen ? (
              <BsChevronDown className="ml-1 text-primary" />
            ) : (
              <BsChevronUp className="ml-1 mb-1 text-primary" />
            )}
          </h6>
          {!isOpen ? (
            <div>{sum} €</div>
          ) : (
            <Button
              tag={Link}
              className="pt-0 pr-0"
              color="link"
              size="sm"
              to={"/cart"}
            >
              <BsPencil size={18} />
            </Button>
          )}
        </div>
        <Collapse isOpen={isOpen}>
          <ListGroup flush className="mt-2">
            <ListGroupItem className="px-0 bg-light pb-0 border-0">
              <div className="d-flex">
                <span>{cartCount} Artikel</span>
                <span className="ml-auto">{formattedTotalPrice}</span>
              </div>
              <OrderDetails />
            </ListGroupItem>
            {discount !== 0 && (
              <ListGroupItem className="d-flex p-0 bg-light border-0">
                <span>Rabatt</span>
                <span className="ml-auto font-size-sm">- {discountSum} €</span>
              </ListGroupItem>
            )}
            <ListGroupItem className="d-flex px-0 pt-0 bg-light">
              <span>Versandkosten</span>
              <span className="ml-auto font-size-sm">1,60 €</span>
            </ListGroupItem>
            <ListGroupItem className="d-flex bg-light lead px-0 pb-4 ">
              <span>Summe</span>
              <span className="ml-auto">{sum} €</span>
            </ListGroupItem>
            <ShippingInfo shipping={shipping} setStep={setStep} />
            <ListGroupItem className="d-flex justify-content-center text-center bg-light py-4">
              <small>Deine Bestellung wird in 1-3 Tagen versandt.</small>
            </ListGroupItem>
          </ListGroup>
        </Collapse>
      </div>
    </StyledMinCard>
  )
}

const OrderDetails = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { cartDetails } = useShoppingCart()
  const cart = Object.entries(cartDetails).map(([k, v]) => v)
  return (
    <>
      <Button
        className="pl-0 text-primary"
        color="Link"
        size="sm"
        onClick={() => setIsOpen(!isOpen)}
      >
        Details {isOpen ? "ausblenden" : "einblenden"}
      </Button>
      <Collapse isOpen={isOpen}>
        <StyledList className="py-2">
          {cart.map((item) => (
            <ListGroupItem
              key={item.id}
              className="d-flex justify-content-between px-0 py-1 bg-light small"
            >
              <a className="text-body d-block" href={`/product/${item.slug}`}>
                <span
                  className="d-inline-block text-truncate"
                  style={{ maxWidth: "12rem" }}
                >
                  {item.quantity}x {item.name} {item.envelope && "mit Umschlag"}
                </span>
              </a>
              <div className="ml-3 text-muted">{item.formattedPrice}</div>
            </ListGroupItem>
          ))}
        </StyledList>
      </Collapse>
    </>
  )
}

const ShippingInfo = (props) => {
  const { shipping, setStep } = props

  if (!shipping) return null

  return (
    <ListGroupItem className="bg-light px-0 pb-4">
      <div className="d-flex justify-content-between align-items-start">
        <h6 className="lead">Versandadresse</h6>
        <Button
          className="pt-0 pr-0"
          color="link"
          size="sm"
          onClick={() => setStep("shipping")}
        >
          <BsPencil size={18} />
        </Button>
      </div>
      <div>
        <div>
          {shipping?.name.given_name} {shipping?.name.surname}
        </div>
        <div>{shipping?.address.address_line_1}</div>
        <div>{shipping?.address.address_line_2}</div>
        <div>
          {shipping?.address.postal_code} {shipping?.address.admin_area_2}
        </div>
      </div>
    </ListGroupItem>
  )
}

const StyledNav = styled.div`
  .btn {
    color: ${({ theme }) => theme.textDefault};
    :hover {
      text-decoration: none;
    }
    :focus {
      text-decoration: none;
    }
  }
  .btn.active {
    color: ${({ theme }) => theme.primary};
    opacity: 1;
  }
`
const StyledList = styled(ListGroup)`
  .list-group-item {
    border: 1px solid #e5e5e5;
    border-top-width: 0;
    border-left: none;
    border-right: none;
    border-radius: 0;
    :last-child {
      border-bottom: none;
    }
    img {
      border-radius: ${({ theme }) => theme.borderRadius};
    }
  }
`

const StyledMinCard = styled(Card)`
  @media (max-width: 576px) {
    margin: 0 -15px;
    border-radius: 0;
    border-left: none;
    border-right: none;
  }
`
