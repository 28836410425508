import React from "react"
import { Formik, Field, Form } from "formik"
import * as Yup from "yup"
import styled from "styled-components"

import { FormGroup, Col, Input, Button, FormFeedback } from "reactstrap"

export default function shipping(props) {
  const { shipping, setStep, setShipping } = props

  const validationSchema = Yup.object().shape({
    givenName: Yup.string().required("Pflichtfeld"),
    surname: Yup.string().required("Pflichtfeld"),
    line1: Yup.string().required("Pflichtfeld"),
    postalCode: Yup.string().required("Pflichtfeld"),
    area2: Yup.string().required("Pflichtfeld"),
  })

  return (
    <Formik
      initialValues={{
        givenName: shipping?.name?.given_name || "",
        surname: shipping?.name?.surname || "",
        line1: shipping?.address?.address_line_1 || "",
        line2: shipping?.address?.address_line_2 || "",
        postalCode: shipping?.address?.postal_code || "",
        area2: shipping?.address?.admin_area_2 || "",
        countryCode: "DE",
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        window.scrollTo(0, 0)
        setStep("payment")
        setShipping({
          name: { given_name: values.givenName, surname: values.surname },
          address: {
            address_line_1: values.line1,
            address_line_2: values.line2,
            postal_code: values.postalCode,
            admin_area_2: values.area2,
            country_code: "DE",
          },
        })
      }}
    >
      {({ errors, touched }) => (
        <StyledForm className={props.className}>
          <h6 className="mb-3 pb-1 text-muted border-bottom">
            Gib deine Lieferadresse an:
          </h6>
          <FormGroup row className="form-row mb-3 mb-md-4">
            <Col xs="12" sm="6" className="mb-3 mb-sm-0">
              <Field
                name="givenName"
                as={Input}
                placeholder="Vorname"
                invalid={errors.givenName && touched.givenName}
              />
              <FormFeedback>{errors.givenName}</FormFeedback>
            </Col>
            <Col xs="12" sm="6">
              <Field
                name="surname"
                as={Input}
                placeholder="Nachname"
                invalid={errors.surname && touched.surname}
              />
              <FormFeedback>{errors.surname}</FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup className="mb-3 mb-md-4">
            <Field
              name="line1"
              as={Input}
              placeholder="Straße und Hausnummer"
              invalid={errors.line1 && touched.line1}
            />
            <FormFeedback>{errors.line1}</FormFeedback>
          </FormGroup>
          <FormGroup className="mb-3 mb-md-4">
            <Field
              name="line2"
              as={Input}
              placeholder="Addresszusatz (optional)"
            />
          </FormGroup>
          <FormGroup row className="form-row mb-3 mb-md-4">
            <Col xs="4" md="3">
              <Field
                name="postalCode"
                as={Input}
                placeholder="PLZ"
                invalid={errors.postalCode && touched.postalCode}
              />
              <FormFeedback>{errors.postalCode}</FormFeedback>
            </Col>
            <Col>
              <Field
                name="area2"
                as={Input}
                placeholder="Ort"
                invalid={errors.area2 && touched.area2}
              />
              <FormFeedback>{errors.area2}</FormFeedback>
            </Col>
          </FormGroup>
          <div className="mb-5 text-center">
            Aktuell werden Bestellungen nur innerhalb von Deutschland
            verschickt!
          </div>
          <div className="d-flex justify-content-end">
            <Button
              className="px-5"
              color="dark"
              size="lg"
              type="submit"
              onClick={() => {
                window.dataLayer = window.dataLayer || []
                window.dataLayer.push({ event: "add_shipping_info" })
              }}
            >
              Weiter zur Zahlung
            </Button>
          </div>
        </StyledForm>
      )}
    </Formik>
  )
}

const StyledForm = styled(Form)`
  input {
    @media (min-width: 576px) {
      height: calc(1.5em + 1rem + 2px);
      padding: 0.5rem 1rem;
      font-size: 1.25rem;
      line-height: 1.5;
      border-radius: 10px;
    }
  }
  .btn {
    @media (max-width: 575px) {
      display: block;
      width: 100%;
    }
  }
`
